<template>
  <main class="my-auto container max-w-lg py-10 md:py-20 text-center">
    <section v-if="isMounted">
      <h1 class="text-20 md:text-26 md:font-300 mb-8">
        找不到頁面
      </h1>
      <nuxt-link
        to="/"
        class="btn-blue"
      >
        前往首頁
      </nuxt-link>
    </section>
  </main>
</template>

<script>
export default {
  data() {
    return {
      isMounted: false,
      isReady: false,
    };
  },

  created() {
    this.initCart();
  },

  async mounted() {
    const self = this;
    if (this.$auth.getToken() || !window.isCheckedLoginStatus) {
      try {
        const result = await this.post(`auth/token`, {}).catch(() => {
          // remove cookie token
          self.$auth.logout();
        });
        window.isCheckedLoginStatus = true;

        const data = result.data;

        this.$store.commit('user/setUser', {
          user: data.user,
          coupons: data.coupons,
          isNewUser: data.isNewUser,
        });
      } catch (e) {}
    }

    this.isReady = true;

    this.initDatalayer();

    const now = new Date().valueOf();

    let timeLastError = localStorage.getItem('timeLastError');
    if (timeLastError) {
      localStorage.setItem('timeLastError', now);
      timeLastError = Number.parseInt(timeLastError);
      let differ = now - timeLastError;
      // if over 30 seconds, reload
      if (differ > 30000) {
        location.reload(true);
      } else {
        this.isMounted = true;
      }
    } else {
      // if no timeLastError always reload
      localStorage.setItem('timeLastError', now);
      location.reload(true);
    }
  },
};
</script>
