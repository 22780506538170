import { findBy } from '../helper';
import { FREE_SHIPPING_AMOUNT, productType } from '../constants/_index';

import axios from 'axios';

/**
 * @returns {{ items: { id: string, quantity: number, memo: string }[], couponCode: string, calculateResult: ?{} }}
 */
export function state() {
  return {
    items: [],
    plusItems: [],
    couponCode: '',
    calculateResult: {},
    priceCredit: 0,
  };
}

/*
..######...########.########.########.########.########...######.
.##....##..##..........##.......##....##.......##.....##.##....##
.##........##..........##.......##....##.......##.....##.##......
.##...####.######......##.......##....######...########...######.
.##....##..##..........##.......##....##.......##...##.........##
.##....##..##..........##.......##....##.......##....##..##....##
..######...########....##.......##....########.##.....##..######.
*/
export const getters = {
  /** 還差多少免運費 */
  freeShippingAmountDifference(state) {
    return state.calculateResult
      ? state.calculateResult.freeShippingAmountDifference
      : FREE_SHIPPING_AMOUNT;
  },

  /** 取得贈送商品數量 */
  getGiveProductQuantity: state => id => {
    try {
      const item = findBy(
        state.calculateResult.giveawayProducts2,
        'productID',
        id
      );
      return item && item.quantity;
    } catch (e) {
      return 0;
    }
  },

  /** 優惠券代碼是否OK */
  isCouponValid(state) {
    return (
      !state.couponCode ||
      (state.calculateResult && state.calculateResult.isCouponCouldUse)
    );
  },

  /** 輸入優惠券代碼的回饋訊息 */
  couponValidationMessage({ couponCode, calculateResult }) {
    if (couponCode && calculateResult) {
      if (calculateResult.isCouponCouldUse) {
        return `優惠券「${calculateResult.coupon.name}」`;
      } else {
        if (calculateResult.isCouponFound) {
          if (calculateResult.isCouponUsed) {
            return `已使用過優惠券「${calculateResult.coupon.name}」`;
          } else if (!calculateResult.isCouponActive) {
            return `優惠券「${calculateResult.coupon.name}」已過期`;
          } else if (calculateResult.isCouponReachLimit) {
            return `優惠券「${calculateResult.coupon.name}」已超過使用上限`;
          } else if (calculateResult.isCouponGiveawayStockNotEnough) {
            return `優惠券「${calculateResult.coupon.name}」 贈品庫存不足`;
          } else {
            return `不適用優惠券「${calculateResult.coupon.name}」`;
          }
        } else {
          return '無效的優惠券代碼';
        }
      }
    } else {
      return '';
    }
  },

  /** 數量總計 */
  totalQuantity(state) {
    return state.items.reduce((sum, item) => sum + item.quantity, 0);
  },
};

/*
.##.....##.##.....##.########....###....########.####..#######..##....##..######.
.###...###.##.....##....##......##.##......##.....##..##.....##.###...##.##....##
.####.####.##.....##....##.....##...##.....##.....##..##.....##.####..##.##......
.##.###.##.##.....##....##....##.....##....##.....##..##.....##.##.##.##..######.
.##.....##.##.....##....##....#########....##.....##..##.....##.##..####.......##
.##.....##.##.....##....##....##.....##....##.....##..##.....##.##...###.##....##
.##.....##..#######.....##....##.....##....##....####..#######..##....##..######.
*/
export const mutations = {
  addItem(
    state,
    {
      id,
      quantity = 1,
      price = 0,
      // memo = '',
      // memoText = '',
      // memoOption = '',
      isFrozenDelivery = false,
      product = null,
    }
  ) {
    if (Number.isInteger(id)) {
      id = id + '';
    }

    const item = findBy(state.items, 'id', id);
    if (!item) {
      this.app.$fbq('track', 'AddToCart', {
        content_type: 'product',
        content_ids: id,
        value: price,
        currency: 'TWD',
      });

      this.app.$gtag('event', 'add_to_cart', { items: [{ id }] });
      let datalayerProduct = null;

      if (product) {
        let category = '--';
        try {
          const temp = productType.find(z => z.id == product.newTypeIDs[0]);
          if (temp) {
            category = temp.name;
          }
        } catch (e) {}

        let brandName = '--';
        try {
          brandName = product.brand.name;
        } catch (e) {}

        datalayerProduct = {
          id: product.id,
          name: product.name,
          category: category,
          price: product.price,
          brand: brandName,
          variant: '',
          quantity: quantity,
        };

        // Add To Cart
        window.dataLayer.push({
          addToCart: {
            products: [datalayerProduct],
          },
          event: 'td_AddToCart',
        });
      }

      state.items.push({
        id,
        quantity,
        isFrozenDelivery,
        datalayerProduct,
      });

      // set localStorae
      try {
        let items = JSON.parse(localStorage.getItem('cartItems'));
        if (items == null) {
          items = [];
        }
        const temp = items.find(z => z.id == id);
        if (!temp) {
          items.push({
            id,
            quantity,
          });
          localStorage.setItem('cartItems', JSON.stringify(items));
        }
      } catch (e) {}

      // add tracing log
      try {
        let tracingID = localStorage.getItem('tracingID');

        const postData = {
          _model: 'addToCart',
          productID: Number.parseInt(id),
        };
        if (tracingID) {
          postData.tracingID = Number.parseInt(tracingID);
        }

        let config = {};

        if (this.$auth.getToken()) {
          config.headers = {
            authorization: `Bearer ${this.$auth.getToken()}`,
          };
        }

        axios.post(`${process.env.API_ORIGIN}/update/update`, postData, config);
      } catch (e) {}
    } else {
      // exists in cart
      item.quantity = quantity;
    }
  },

  addPlusItem(state, { id, quantity = 1, product = null }) {
    const item = findBy(state.plusItems, 'id', id);
    if (!item) {
      this.app.$fbq('track', 'AddToCart', {
        content_type: 'product',
        content_ids: id,
        value: product.price,
        currency: 'TWD',
      });

      this.app.$gtag('event', 'add_to_cart', { items: [{ id }] });
      let datalayerProduct = null;

      if (product) {
        let category = '--';
        try {
          const temp = productType.find(z => z.id == product.newTypeIDs[0]);
          if (temp) {
            category = temp.name;
          }
        } catch (e) {}

        let brandName = '--';
        try {
          brandName = product.brand.name;
        } catch (e) {}

        datalayerProduct = {
          id: product.id,
          name: product.name,
          category: category,
          price: product.price,
          brand: brandName,
          variant: '',
          quantity: quantity,
        };

        // Add To Cart
        window.dataLayer.push({
          addToCart: {
            products: [datalayerProduct],
          },
          event: 'td_AddToCart',
        });
      }

      state.plusItems.push({
        id,
        quantity,
      });

      // set localStorage
      try {
        let plusItems = JSON.parse(localStorage.getItem('cartPlusItems'));
        if (plusItems == null) {
          plusItems = [];
        }
        const temp = plusItems.find(z => z.id == id);
        if (!temp) {
          plusItems.push({
            id,
            quantity,
          });
          localStorage.setItem('cartPlusItems', JSON.stringify(plusItems));
        }
      } catch (e) {}
    } else {
      // exists in cart
      item.quantity = quantity;
    }
  },

  removeItem(state, { id }) {
    const itemIndex = state.items.findIndex(z => z.id == id);

    if (itemIndex !== -1) {
      this.app.$gtag('event', 'remove_from_cart', {
        items: [{ id }],
      });

      try {
        if (state.items[itemIndex].datalayerProduct) {
          var product = state.items[itemIndex].datalayerProduct;

          // Remove From Cart
          window.dataLayer.push({
            remove: {
              products: [product],
            },
            event: 'td_removeFromCart',
          });
        }
      } catch (e) {}
      state.items.splice(itemIndex, 1);

      try {
        let items = JSON.parse(localStorage.getItem('cartItems'));
        if (items == null) {
          items = [];
        }
        const index = items.findIndex(z => z.id == id);
        items.splice(index, 1);
        localStorage.setItem('cartItems', JSON.stringify(items));
      } catch {}

      // check is clean plusItems
      if (state.items.filter(z => z.id == 3606 || z.id == 3605).length <= 0) {
        state.plusItems = [];
        localStorage.setItem('cartPlusItems', JSON.stringify([]));
      }
    }
  },

  removePlusItem(state, { id }) {
    const itemIndex = state.plusItems.findIndex(z => z.id == id);

    if (itemIndex !== -1) {
      this.app.$gtag('event', 'remove_from_cart', {
        items: [{ id }],
      });

      try {
        if (state.plusItems[itemIndex].datalayerProduct) {
          var product = state.plusItems[itemIndex].datalayerProduct;

          // Remove From Cart
          window.dataLayer.push({
            remove: {
              products: [product],
            },
            event: 'td_removeFromCart',
          });
        }
      } catch (e) {}
      state.plusItems.splice(itemIndex, 1);

      try {
        let plusItems = JSON.parse(localStorage.getItem('cartPlusItems'));
        if (plusItems == null) {
          plusItems = [];
        }
        const index = plusItems.findIndex(z => z.id == id);
        plusItems.splice(index, 1);
        localStorage.setItem('cartPlusItems', JSON.stringify(plusItems));
      } catch {}
    }
  },

  cleanPlusItems(state) {
    state.plusItems = [];
    localStorage.setItem('cartPlusItems', JSON.stringify([]));
  },

  increaseItemQuantity(state, { id, amount = 1 }) {
    const item = findBy(state.items, 'id', id);
    if (item) {
      item.quantity += amount;
      try {
        let items = JSON.parse(localStorage.getItem('cartItems'));
        if (items == null) {
          items = [];
        }
        const temp = items.find(z => z.id == id);
        if (temp) {
          temp.quantity = item.quantity;
        }
        localStorage.setItem('cartItems', JSON.stringify(items));
      } catch {}
    }
  },

  decreaseItemQuantity(state, { id, amount = 1 }) {
    const item = findBy(state.items, 'id', id);
    if (item && item.quantity - amount >= 1) {
      item.quantity -= amount;
      try {
        let items = JSON.parse(localStorage.getItem('cartItems'));
        if (items == null) {
          items = [];
        }
        const temp = items.find(z => z.id == id);
        if (temp) {
          temp.quantity = item.quantity;
        }
        localStorage.setItem('cartItems', JSON.stringify(items));
      } catch {}
    }
  },

  /*
    .########..##.......##.....##..######.
    .##.....##.##.......##.....##.##....##
    .##.....##.##.......##.....##.##......
    .########..##.......##.....##..######.
    .##........##.......##.....##.......##
    .##........##.......##.....##.##....##
    .##........########..#######...######.
    */

  increasePlusItemQuantity(state, { id, amount = 1 }) {
    const item = findBy(state.plusItems, 'id', id);
    if (item) {
      item.quantity += amount;
      try {
        let plusItems = JSON.parse(localStorage.getItem('cartPlusItems'));
        if (plusItems == null) {
          plusItems = [];
        }
        const temp = plusItems.find(z => z.id == id);
        if (temp) {
          temp.quantity = item.quantity;
        }
        localStorage.setItem('cartPlusItems', JSON.stringify(plusItems));
      } catch {}
    }
  },

  decreasePlusItemQuantity(state, { id, amount = 1 }) {
    const item = findBy(state.plusItems, 'id', id);
    if (item && item.quantity - amount >= 1) {
      item.quantity -= amount;
      try {
        let plusItems = JSON.parse(localStorage.getItem('cartPlusItems'));
        if (plusItems == null) {
          plusItems = [];
        }
        const temp = plusItems.find(z => z.id == id);
        if (temp) {
          temp.quantity = item.quantity;
        }
        localStorage.setItem('cartPlusItems', JSON.stringify(plusItems));
      } catch {}
    }
  },

  setItemMemo(state, { id, memo = '' }) {
    const item = findBy(state.items, 'id', id);
    if (item) {
      item.memo = memo;
    }
  },

  applyCouponCode(state, { couponCode = '' } = {}) {
    state.couponCode = couponCode;
  },

  applyPriceCredit(state, priceCredit = 0) {
    state.priceCredit = priceCredit;
    localStorage.setItem('priceCredit', priceCredit);
  },

  setCart(state, { cart: { items = [], couponCode = '' } = {} } = {}) {
    state.items = items;
    state.couponCode = couponCode;
  },

  setCoupon(state, couponCode) {
    state.couponCode = couponCode;
  },

  initCart(state, items) {
    state.items = items;
  },

  initPriceCredit(state, priceCredit) {
    state.priceCredit = priceCredit;
  },

  initCartPlus(state, items) {
    state.plusItems = items;
  },

  clean(state) {
    localStorage.setItem('cartItems', '[]');
    localStorage.setItem('cartPlusItems', '[]');
    localStorage.setItem('priceCredit', 0);

    state.items = [];
    state.plusItems = [];
    state.priceCredit = [];
  },
};

/*
....###.....######..########.####..#######..##....##..######.
...##.##...##....##....##.....##..##.....##.###...##.##....##
..##...##..##..........##.....##..##.....##.####..##.##......
.##.....##.##..........##.....##..##.....##.##.##.##..######.
.#########.##..........##.....##..##.....##.##..####.......##
.##.....##.##....##....##.....##..##.....##.##...###.##....##
.##.....##..######.....##....####..#######..##....##..######.
*/
export const actions = {
  clean() {
    localStorage.setItem('cartItems', '[]');
    localStorage.setItem('cartPlusItems', '[]');
  },
};
