/**
 * 電子發票共通性載具類型
 */
const receiptCarrierType = [
  // { id: 1, identifier: 'MOBILE', name: '手機條碼' },
  { id: 1, identifier: 'MOBILE', name: '手機載具' },
  { id: 2, identifier: 'CITIZEN_DIGITAL_CERTIFICATE', name: '自然人憑證條碼' },
];

export default receiptCarrierType;
