<template>
  <main
    :class="$style.bg"
    class="md:min-h-screen"
  >
    <div class="container px-0 md:px-8 pb-0 lg:pb-12 md:pt-12">
      <header class="bg-f5f5f5 md:bg-transparent flex items-center justify-center md:justify-start h-16 md:h-auto md:mb-8">
        <nuxt-link to="/">
          <img
            src="https://d1jjjfa1mlyr2v.cloudfront.net/c/assets/logotype-unipapa.svg"
            alt="Unipapa"
            height="20"
            class="flex-none"
          >
        </nuxt-link>
      </header>
      <nuxt v-if="isReady" />
    </div>
  </main>
</template>

<script>
export default {
  data() {
    return {
      isReady: false,
    };
  },

  created() {
    this.initCart();
  },

  async mounted() {
    const self = this;

    if (this.$auth.getToken() && !window.isCheckedLoginStatus) {
      try {
        const result = await this.post(`auth/token`, {}).catch(() => {
          // remove cookie token
          self.$auth.logout();
        });
        window.isCheckedLoginStatus = true;

        const data = result.data;

        this.$store.commit('user/setUser', {
          user: data.user,
          coupons: data.coupons,
          isNewUser: data.isNewUser,
        });
      } catch (e) {}
    }

    this.isReady = true;

    this.initDatalayer();
  },
};
</script>

<style module>
@screen md {
  .bg {
    background-image: linear-gradient(to right, #ffffff 50%, #f9f9f9 50%);
  }
}
</style>
