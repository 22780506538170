<template>
  <main class="grid-container">
    <div
      v-for="(x, i) of $store.state.site.notification"
      :key="i"
      class="border-ccc border rounded-5 bg-yellow px-5 py-4 "
    >
      <!--
      ..######..##.....##....###....##....##..######...########.....######..########..########.########..####.########
      .##....##.##.....##...##.##...###...##.##....##..##..........##....##.##.....##.##.......##.....##..##.....##...
      .##.......##.....##..##...##..####..##.##........##..........##.......##.....##.##.......##.....##..##.....##...
      .##.......#########.##.....##.##.##.##.##...####.######......##.......########..######...##.....##..##.....##...
      .##.......##.....##.#########.##..####.##....##..##..........##.......##...##...##.......##.....##..##.....##...
      .##....##.##.....##.##.....##.##...###.##....##..##..........##....##.##....##..##.......##.....##..##.....##...
      ..######..##.....##.##.....##.##....##..######...########.....######..##.....##.########.########..####....##...
      -->
      <div
        v-if="x.typeID == 1"
        :key="i"
        class="flex justify-between"
      >
        <div class="font-bold">
          您的信用卡已經變更成功!
        </div>
        <span
          class="material-icons pointer"
          @click="$store.commit('site/clearNotification', i)"
        >
          clear
        </span>
      </div>

      <!--
      ..######..##.....##....###....##....##..######...########....########..##..........###....##....##
      .##....##.##.....##...##.##...###...##.##....##..##..........##.....##.##.........##.##...###...##
      .##.......##.....##..##...##..####..##.##........##..........##.....##.##........##...##..####..##
      .##.......#########.##.....##.##.##.##.##...####.######......########..##.......##.....##.##.##.##
      .##.......##.....##.#########.##..####.##....##..##..........##........##.......#########.##..####
      .##....##.##.....##.##.....##.##...###.##....##..##..........##........##.......##.....##.##...###
      ..######..##.....##.##.....##.##....##..######...########....##........########.##.....##.##....##
      -->
      <div
        v-if="x.typeID == 2"
        :key="i"
        class=" "
      >
        <div class="flex justify-between mb-3">
          <div class="font-bold">
            已經成功變更您的方案
          </div>
          <span
            class="material-icons pointer"
            @click="$store.commit('site/clearNotification', i);"
          >
            clear
          </span>
        </div>
        <div>您變更的方案為: </div>
        <hr class="my-3">
        <div class="flex">
          <div class="text-999 mr-5">
            訂單編號
          </div>
          <div>{{ x.code }}</div>
        </div>
        <hr class="my-3">

        <template v-if="x.isShowConfirm">
          <div class="mb-1">
            確認立即配送: 
          </div>
          <div class="mb-3">
            使用「立即配送」功能，會將之前的「配送日期」改為「今日」開始計算，我們將會立即開始處理您的訂單並出貨。（工作時間為 5 個工作日。）您確定要改為今日立即配送嗎？
          </div>
          <div class="text-center">
            <button
              class="border py-2 px-8 rounded-full border-ccc text-999 bg-white"
              @click="deliverNow(x, i)"
            >
              確定立即配送
            </button>
          </div>
        </template>
        <template v-else>
          <div class="mb-3">
            也許您會想使用立即配送功能: 
          </div>
          <div class="text-center">
            <button
              class="border py-2 px-8 rounded-full border-ccc text-999 bg-white"
              @click="changeConfirm(i)"
            >
              立即配送
            </button>
          </div>
        </template>

        <div class="font-bold text-right mt-5">
          Unipapa 感謝您
        </div>
      </div>

      <!--
      ..######.....###....##....##..######..########.##......
      .##....##...##.##...###...##.##....##.##.......##......
      .##........##...##..####..##.##.......##.......##......
      .##.......##.....##.##.##.##.##.......######...##......
      .##.......#########.##..####.##.......##.......##......
      .##....##.##.....##.##...###.##....##.##.......##......
      ..######..##.....##.##....##..######..########.########
      -->
      <div
        v-if="x.typeID == 3"
        :key="i"
        class=" "
      >
        <div class="flex justify-between mb-3">
          <div class="font-bold">
            您已經成功取消此配送方案
          </div>
          <span
            class="material-icons pointer"
            @click="$store.commit('site/clearNotification', i);"
          >
            clear
          </span>
        </div>
        <div>您成功取消的方案為: </div>
        <hr class="my-3">
        <div class="flex">
          <div class="text-999 mr-5">
            訂單編號
          </div>
          <div>{{ x.code }}</div>
        </div>
        <hr class="my-3">
        <div class="font-bold text-right mt-5">
          Unipapa 感謝您
        </div>
      </div>


      <!--
      .########..########.##.......####.##.....##.########.########..##....##
      .##.....##.##.......##........##..##.....##.##.......##.....##..##..##.
      .##.....##.##.......##........##..##.....##.##.......##.....##...####..
      .##.....##.######...##........##..##.....##.######...########.....##...
      .##.....##.##.......##........##...##...##..##.......##...##......##...
      .##.....##.##.......##........##....##.##...##.......##....##.....##...
      .########..########.########.####....###....########.##.....##....##...
      -->
      <div
        v-if="x.typeID == 4"
        :key="i"
        class=" "
      >
        <div class="flex justify-between mb-3">
          <div class="font-bold">
            已經成功受理您的立即配送
          </div>
          <span
            class="material-icons pointer"
            @click="$store.commit('site/clearNotification', i);"
          >
            clear
          </span>
        </div>
        <div>目前生活小管家已經為您開始立即配送</div>
        <hr class="my-3">
        <div class="flex">
          <div class="text-999 mr-5">
            訂單編號
          </div>
          <div>{{ x.code }}</div>
        </div>
        <hr class="my-3">
        <div class="font-bold text-right mt-5">
          Unipapa 感謝您
        </div>
      </div>
    </div>
  </main>
</template>

<script>
export default {
  methods: {
    changeConfirm(index) {
      this.$store.commit('site/changeNotification', index);
    },

    deliverNow(q, index) {
      const self = this;

      this.post(`update/update`, {
        _model: 'orderSubscribeDelivery',
        item: q.item,
        isDeliverNow: true,
      }).then(function(r) {
        const response = r.data;
        self.isProcessing = false;

        if (response.statusID == 0) {
          self.isShowModal = false;

          self.$store.commit('site/clearNotification', index);
          self.$store.commit('site/addNotification', {
            typeID: 4,
            code: q.item.code,
          });
        } else {
          // failed
          self.isShowModal = false;
          self.$toasted.global.error({
            message: '立即配送失敗: ' + response.message,
          });
        }
      });
    },
  },
};
</script>
