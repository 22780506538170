import constants from '../constants/_index';
import axios from 'axios';

export default {
  computed: {
    constants: () => constants,
  },

  data() {
    return {
      option: constants,
      isDev: process.env.NODE_ENV == 'development',
    };
  },

  scrollToTop: true,

  methods: {
    onMounted() {},

    getProductUrl(q) {
      try {
        return (
          '/products/' +
          q.id +
          '/' +
          encodeURIComponent(q.name.replace(/\//g, '-'))
        );
      } catch (e) {
        return '/';
      }
    },

    initCart() {
      if (process.client) {
        let items = [];
        let plusItems = [];
        let priceCredit = 0;

        try {
          items = JSON.parse(localStorage.getItem('cartItems'));
          if (items == null) {
            items = [];
          }
        } catch {}

        try {
          plusItems = JSON.parse(localStorage.getItem('cartPlusItems'));
          if (plusItems == null) {
            plusItems = [];
          }
        } catch {}

        try {
          priceCredit = localStorage.getItem('priceCredit');
          if (priceCredit) {
            priceCredit = parseInt(priceCredit);
            if (priceCredit < 0) {
              priceCredit = 0;
            }
          }
        } catch {}

        this.$store.commit('cart/initCart', items);
        this.$store.commit('cart/initCartPlus', plusItems);
        this.$store.commit('cart/initPriceCredit', priceCredit);
      }
    },

    async postAsync(url, data) {
      let config = {};

      if (this.$auth.getToken()) {
        config.headers = {
          authorization: `Bearer ${this.$auth.getToken()}`,
        };
      }

      return axios.post(`${process.env.API_ORIGIN}/${url}`, data, config);
    },

    post(url, data) {
      let config = {};

      if (this.$auth.getToken()) {
        config.headers = {
          authorization: `Bearer ${this.$auth.getToken()}`,
        };
      }

      return axios.post(`${process.env.API_ORIGIN}/${url}`, data, config);
    },

    initDatalayer() {
      // datalayer
      if (this.$store.state.user.isLogin) {
        window.dataLayer = window.dataLayer || [];

        if (window.isInitDatalayer != true) {
          window.dataLayer.push({
            event: 'send_UserID',
            User_id: this.$store.state.user.user.id,
            User_level: '',
            user_email: this.$store.state.user.user.email,
            new_user: this.$store.state.user.isNewUser,
          });
        }
        window.isInitDatalayer = true;
      }
    },

    getType(key, index) {
      return constants[key].find(z => z.id == index);
    },

    removeItem(q, qq) {
      q.splice(qq, 1);
    },

    isEmpty(q) {
      return q === '' || q === null;
    },

    isInArray(q, qq) {
      try {
        return q.find(z => z.id == qq) != null;
      } catch (e) {
        return false;
      }
    },

    typeText(key, index, defaultText = '--') {
      const temp = constants[key].find(z => z.id == index);
      if (temp) {
        defaultText = temp.name;
      }
      return defaultText;
    },

    pushProductDetailDatalayer(products) {
      // process products
      let datalayerProducts = [];
      for (const i in products) {
        const x = products[i];

        let category = '--';
        try {
          category = this.typeText('productType', x.newTypeIDs[0]);
        } catch (e) {}

        let brandName = '--';
        try {
          brandName = x.brandName;
        } catch (e) {}

        const a = {
          id: x.id,
          name: x.nameFull,
          category: category,
          price: x.price,
          brand: brandName,
          variant: '',
        };

        datalayerProducts.push(a);
      }

      // Product Detail
      window.dataLayer.push({
        ecommerce: {
          detail: {
            products: datalayerProducts,
          },
        },
        event: 'td_productDetail',
      });
    },
  },
};
