<template>
  <header
    class="pin-t pin-x h-screen flex flex-col pointer-events-none md:hidden"
  >
    <div
      class="flex-none flex pointer-events-auto headerbar  "
      :class="{
        'bg-333': isBlack() ,
        'bg-fcfcfc95': !isBlack() && isHeaderRaised || isMenuOpen
      }"
    >
      <button
        id="header-menu-toggle"
        :class="{
          'text-on-dark': $route.meta.theme === 'dark' && !isMenuOpen
        }"
        :aria-expanded="isMenuOpen"
        type="button"
        class="flex-none px-4"
        aria-label="主要連結"
        aria-haspopup="true"
        aria-controls="header-menu"
        @click="toggle"
      >
        <no-ssr>
          <div
            v-if="!isMenuOpen"
          >
            <template v-if="$store.state.user.isLogin">
              <img
                class="h-6"
                src="https://d1jjjfa1mlyr2v.cloudfront.net/c/assets/icon-bar-m.svg"
              >
            </template>
            <img
              v-else
              class="w-full"
              src="https://d1jjjfa1mlyr2v.cloudfront.net/c/assets/icon-bar-m.svg"
            >
          </div>
          <img
            v-else
            class="h-6"
            src="https://d1jjjfa1mlyr2v.cloudfront.net/c/assets/icon-cross-m.svg"
          >
        </no-ssr>
      </button>

      <nuxt-link
        to="/"
        class="flex-none mx-auto flex items-center px-5"
      >
        <img
          v-if="!isBlack()"
          src="https://d1jjjfa1mlyr2v.cloudfront.net/c/assets/logotype-unipapa.svg"
          alt="Unipapa"
          height="24"
          class="flex-none h-6"
        >
        <img
          v-else
          src="https://d1jjjfa1mlyr2v.cloudfront.net/c/assets/logotype-unipapa-dark.svg"
          alt="Unipapa"
          height="24"
          class="flex-none h-6"
        >
      </nuxt-link>
      
      <nuxt-link
        aria-label="購物車"
        to="/cart"
        class="flex-none flex items-center px-4"
      >
        <div class="relative">
          <img
            class="w-full"
            src="https://d1jjjfa1mlyr2v.cloudfront.net/c/assets/icon-cart.svg"
          >
          <no-ssr>
            <div
              :class="{
                'bg-black': !$route.meta.theme,
                'text-on-dark': !$route.meta.theme,
                'bg-white': $route.meta.theme === 'dark',
                'text-on-light': $route.meta.theme === 'dark'
              }"
              class="absolute pin-r pin-t w-5 h-5 flex items-center justify-center   font-700 tracking-normal leading-none rounded-full select-none"
              style="top:-10px; right:-10px"
            >
              {{ $store.getters['cart/totalQuantity'] }}
            </div>
          </no-ssr>
        </div>
      </nuxt-link>
    </div>
    <!-- header bar -->

    <!-- header menu -->
    <nav
      id="header-menu"
      :class="{
        'pointer-events-auto': isMenuOpen,
        'opacity-0': !isMenuOpen,
      }"
      aria-labelledby="header-menu-toggle"
      class="flex-auto overflow-x-auto text-center bg-f9f9f9ee"
    >
      <div class="container py-4 flex flex-col text-center">
        <div class="mb-5 text-333">
          <form
            ref="searchForm"
            class="flex mb-8"
            role="search"
            aria-label="商品"
            @submit.prevent="search"
          >
            <div class="relative w-full">
              <input
                ref="searchInput"
                type="text"
                aria-label="搜尋商品"
                placeholder="輸入商品關鍵字..."
                class="bg-white"
              >
              <button
                aria-label="搜尋"
                type="submit"
                :class="{
                  'text-on-dark': $route.meta.theme === 'dark',
                }"
                class="absolute  "
                style="right:8px; top:10px;"
              >
                <span class="icon-unipapa icon-search text-26" />
              </button>
            </div>
          </form>

          <div class="flex flex-wrap -mx-1 text-left">
            <nuxt-link
              class="w-1/2 px-1 mb-3"
              to="/article/listing"
            >
              <div class="flex rounded-10 bg-white items-center   py-3 border-yada haohao">
                <div class="flex-auto text-center">
                  文章特集
                </div>
              </div>
            </nuxt-link>
            <nuxt-link
              class="w-1/2 px-1 mb-3"
              to="/shop/index"
            >
              <div class="flex rounded-10 bg-white items-center   py-3 border-yada haohao">
                <div class="flex-auto text-center">
                  實體販售地點
                </div>
              </div>
            </nuxt-link>
            <nuxt-link
              class="w-1/2 px-1 mb-3"
              to="/order-subscribe/index"
            >
              <div class="flex rounded-10 bg-white items-center   py-3 border-yada haohao">
                <div class="flex-auto text-center">
                  叮咚！訂閱配送
                </div>
              </div>
            </nuxt-link>
          </div>
        </div>

        <div class="w-full text-333 mt-5">
          <div class="text-10 text-left mb-3 text-999">
            商品分類
          </div>
          <nuxt-link
            class="border-yada text-center py-2 rounded-10 mb-3 bg-white block"
            to="/product/listing/3"
          >
            電器製品
          </nuxt-link>
         
          <nuxt-link
            class="border-yada text-center py-2 rounded-10 mb-3 bg-white block"
            to="/product/listing/2"
          >
            防蚊防蟲
          </nuxt-link>
     
          <nuxt-link
            class="border-yada text-center py-2 rounded-10 mb-3 bg-white block"
            to="/product/listing/4"
          >
            生活用品
          </nuxt-link>
        </div>

        <div
          v-if="$store.state.user.isLogin"
        >
          <div class="text-10 text-left mb-3 text-999 mt-5">
            帳號資料
          </div>

          <div class="border-yada bg-white rounded-10 p-5 text-333 mb-10">
            <div class="flex">
              <div>
                <nuxt-link
                  aria-label="帳戶資料"
                  to="/user"
                  class="py-4 text-18 "
                >
                  <vue-avatar
                    v-if="$store.state.user.user.avatar"
                    :username="$store.state.user.user.name"
                    :size="60"
                    :src="$store.state.user.user.avatar | assetURL"
                    class="mx-auto"
                  />
                  <vue-avatar
                    v-else
                    :username="$store.state.user.user.name"
                    :size="60"
                    background-color="#000000"
                    color="#FFFFFF"
                    class="mx-auto"
                  />
                </nuxt-link>
              </div>

              <div class="ml-5 text-left">
                <div class="text-16 mb-1 mt-1">
                  {{ $store.state.user.user.name }}
                </div>
                <div>{{ $store.state.user.user.email }}</div>
              </div>
            </div>

            <hr>

            <nuxt-link
              class="border-yada text-center py-2 rounded-full mb-3 block"
              to="/user"
            >
              帳號設定
            </nuxt-link>

            <nuxt-link
              class="border-yada text-center py-2 rounded-full mb-3 block"
              to="/orders"
            >
              訂單紀錄
            </nuxt-link>

            <nuxt-link
              class="border-yada text-center py-2 rounded-full mb-3 block"
              to="/user-order-subscribe/listing"
            >
              訂閱控制台
            </nuxt-link>
            <div
              class="border-yada text-center py-2 text-blue rounded-full"
              @click="logoutDo()"
            >
              登出
            </div>
          </div>
        </div>

        <div v-else>
          <hr>

          <nuxt-link
            class="border-yada text-blue text-center py-2 rounded-full mb-8 block bg-white"
            to="/login"
          >
            登入
          </nuxt-link>
        </div>
        <div class="h-20" />
      </div>
    </nav>
    <!-- header menu -->
  </header>
</template>

<script>
import scrollMonitor from 'scrollmonitor';

export default {
  data() {
    return {
      isHeaderRaised: false,
      isMenuOpen: false,
    };
  },

  watch: {
    $route() {
      this.close();
    },
  },

  mounted() {
    this.$_scrollWatcher = scrollMonitor.create(16 * 4);
    this.isHeaderRaised = !this.$_scrollWatcher.isInViewport;
    this.$_scrollWatcher.on('stateChange', () => {
      this.isHeaderRaised = !this.$_scrollWatcher.isInViewport;
    });
  },

  beforeDestroy() {
    try {
      this.$_scrollWatcher.destroy();
    } catch (e) {}
  },

  methods: {
    isBlack() {
      let isBlack = false;

      if (this.$route.meta.theme === 'dark') {
        isBlack = true;
        if (this.isMenuOpen == true) {
          isBlack = false;
        }
      } else {
        isBlack = false;
      }

      if (!this.isHeaderRaised) {
        if (this.$route.meta.theme !== 'dark') {
          isBlack = false;
        }
      }

      return isBlack;
    },

    logoutDo() {
      this.$store.commit('site/showProfileModal', false);
      this.$auth.logout();
      this.$router.push('/');
    },

    close() {
      this.isMenuOpen = false;
      document.body.classList.remove('overflow-hidden');
    },

    toggle() {
      this.isMenuOpen = !this.isMenuOpen;
      document.body.classList.toggle('overflow-hidden', this.isMenuOpen);
    },

    search() {
      const term = this.$refs.searchInput.value;
      if (term) {
        this.$router.push(`/search?query=${term}`);
        this.$refs.searchForm.reset();
      }
    },
  },
};
</script>
