/**
 * 發票類型
 */
const receiptType = [
  // { id: 4, identifier: 'EINVOICE', name: '電子發票' },
  { id: 4, identifier: 'EINVOICE', name: '雲端發票' },
  {
    id: 1,
    identifier: 'CARRIER',
    name: '電子發票 - 歸戶至共通性載具',
  },
  {
    id: 2,
    identifier: 'DONATE',
    name: '電子發票 - 捐贈至財團法人臺灣世界展望會',
  },
  // { id: 3, identifier: 'TRIPLICATE', name: '三聯式紙本發票' },
  {
    id: 3,
    identifier: 'TRIPLICATE',
    name: '三聯式發票（統編）',
  },
];

export default receiptType;
