import Vue from 'vue';

export default () => {
  function dateSlash(q) {
    try {
      q = q.replace(/-/g, '/');
    } catch (e) {}
    return q;
  }

  Vue.filter('dateSlash', dateSlash);
};
