import Vue from 'vue';
import Cookies from 'js-cookie';

export default ({ req, app, store, redirect }, inject) => {
  /** @param {string} tokenName */
  function getToken(tokenName = process.env.AUTH_TOKEN_NAME) {
    return process.server ? req.cookies[tokenName] : Cookies.get(tokenName);
  }

  /**
   * @param {string} tokenName
   * @param {CookieAttributes} options
   **/
  function removeToken(tokenName = process.env.AUTH_TOKEN_NAME, options = {}) {
    if (process.server) {
      throw new Error("Can't invoke client-side method.");
    } else {
      if (process.env.NODE_ENV == 'development') {
        Cookies.remove(
          tokenName,
          Object.assign({ domain: 'localhost' }, options)
        );
      } else {
        Cookies.remove(
          tokenName,
          Object.assign({ domain: 'unipapa.com' }, options)
        );
      }
    }
  }

  /**
   * @param {string} tokenName
   * @param {CookieAttributes} options
   **/
  function logout(tokenName, options) {
    try {
      if (process.server) {
        throw new Error("Can't invoke client-side method.");
      } else {
        removeToken(tokenName, options);

        store.commit('user/setUser');

        store.commit('cart/clean');

        if (
          app.router.currentRoute.matched.some(
            matchedRoute => matchedRoute.meta.isRequireAuth
          )
        ) {
          redirect('/');
        }
      }
    } catch (e) {}
    Vue.toasted.show('已登出', { icon: 'exit_to_app' });
  }

  inject('auth', { getToken, removeToken, logout });
};
