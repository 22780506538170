<template>
  <div>
    <!--
    .##.....##.########....###....########..########.########.
    .##.....##.##.........##.##...##.....##.##.......##.....##
    .##.....##.##........##...##..##.....##.##.......##.....##
    .#########.######...##.....##.##.....##.######...########.
    .##.....##.##.......#########.##.....##.##.......##...##..
    .##.....##.##.......##.....##.##.....##.##.......##....##.
    .##.....##.########.##.....##.########..########.##.....##
    -->

    <TheHeaderMd
      :class="headerPosition"
      class="z-2"
    />
    <TheHeader
      :class="headerPosition"
      class="z-2"
    />

    <div
      :class="{
        [$style['is-push-down']]: !$route.meta.isPullUp,
      }"
      class="min-h-screen flex flex-col relative z-1"
    >
      <!--
      ..######...#######..##....##.########.########.##....##.########
      .##....##.##.....##.###...##....##....##.......###...##....##...
      .##.......##.....##.####..##....##....##.......####..##....##...
      .##.......##.....##.##.##.##....##....######...##.##.##....##...
      .##.......##.....##.##..####....##....##.......##..####....##...
      .##....##.##.....##.##...###....##....##.......##...###....##...
      ..######...#######..##....##....##....########.##....##....##...
      -->
      <div class="flex-auto flex flex-col">
        <transition name="bounce">
          <nuxt v-if="isReady" />
        </transition>
      </div>

      <!-- footer spacing -->
      <div
        v-if="!$route.meta.isFooterSpacing && $route.meta.isFooterSpacing !== false"
        class="flex-none h-20 lg:h-20"
      />

      <!--
      .########..#######...#######..########.########.########.
      .##.......##.....##.##.....##....##....##.......##.....##
      .##.......##.....##.##.....##....##....##.......##.....##
      .######...##.....##.##.....##....##....######...########.
      .##.......##.....##.##.....##....##....##.......##...##..
      .##.......##.....##.##.....##....##....##.......##....##.
      .##........#######...#######.....##....########.##.....##
      -->
      <footer
        id="footer"
        class="bg-333 text-white py-10 text-14"
      >
        <div class="container">
          <nav aria-label="頁尾">
            <div class="flex flex-wrap flex-col-reverse items-center justify-between mb-6 flex-row-reverse lg:flex-row">
              <div class="self-end w-full lg:w-auto">
                <ul
                  class="row list-reset"
                >
                  <li class="col mr-3 mb-3 lg:mb-0">
                    <nuxt-link
                      to="/shop/index"
                    >
                      實體販售地點
                    </nuxt-link>
                  </li>
                  <li class="col mr-3 mb-3 lg:mb-0">
                    <nuxt-link
                      to="/jobs"
                    >
                      人才招募
                    </nuxt-link>
                  </li>
                  <li class="col mr-3 mb-3 lg:mb-0">
                    <nuxt-link
                      to="/about/service"
                    >
                      支援服務
                    </nuxt-link>
                  </li>
                  <li
                    class="col mr-3 mb-3 lg:mb-0"
                  >
                    <nuxt-link
                      to="/enterprisePurchase/create"
                    >
                      企業採購
                    </nuxt-link>
                  </li>
                </ul>
              </div>

              <div class="self-start mb-5 lg:mb-0">
                <ul class="flex-auto flex md:justify-end list-reset">
                  <li class="mr-6 lg:mr-8">
                    <a
                      aria-label="Unipapa@Facebook"
                      href="https://www.facebook.com/unipapa"
                      target="_blank"
                      rel="noopener"
                      class="block"
                    >
                      <span class="icon-unipapa icon-footer-fb text-26" />
                    </a>
                  </li>
                  <li class="mr-6 lg:mr-8">
                    <a
                      aria-label="Unipapa@Line"
                      href="https://lin.ee/7EEJdBw"
                      target="_blank"
                      rel="noopener"
                      class="block"
                    >
                      <span class="icon-unipapa icon-footer-line text-26" />
                    </a>
                  </li>
                  <li>
                    <a
                      aria-label="Unipapa@Instagram"
                      href="https://www.instagram.com/unipapa_official/"
                      target="_blank"
                      rel="noopener"
                      class="block"
                    >
                      <span class="icon-unipapa icon-footer-ig text-26" />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </nav>
          <hr class="mt-2">
          <div class="flex flex-wrap items-center justify-between mb-3 mt-2">
            <div class="w-full lg:w-auto mb-3">
              有理百物股份有限公司
            </div>
            <div class="w-full lg:w-auto">
              <span>&copy; Unipapa Inc. All rights reserved.</span>
            </div>
          </div>
        </div>
      </footer>
    </div>


    <!--
    .########..########...#######..########.####.##.......########....##.....##..#######..########.....###....##......
    .##.....##.##.....##.##.....##.##........##..##.......##..........###...###.##.....##.##.....##...##.##...##......
    .##.....##.##.....##.##.....##.##........##..##.......##..........####.####.##.....##.##.....##..##...##..##......
    .########..########..##.....##.######....##..##.......######......##.###.##.##.....##.##.....##.##.....##.##......
    .##........##...##...##.....##.##........##..##.......##..........##.....##.##.....##.##.....##.#########.##......
    .##........##....##..##.....##.##........##..##.......##..........##.....##.##.....##.##.....##.##.....##.##......
    .##........##.....##..#######..##.......####.########.########....##.....##..#######..########..##.....##.########
    -->
    <div
      class="pin-x pin-y fixed w-screen h-screen modal-login"
      :class=" { 'active': $store.state.site.isShowProfileModal }"
      @click="$store.commit('site/showProfileModal', false)"
    >
      <div
        class="absolute bg-white px-6 py-8 modal-login-frame rounded-10 "
        @click.stop.prevent
      >
        <div
          v-if="$store.state.user.user"
          class="flex"
        >
          <div class="mr-5">
            <vue-avatar
              v-if="$store.state.user.user.avatar"
              :username="$store.state.user.user.name"
              :size="64"
              :src="$store.state.user.user.avatar | assetURL"
              class="mx-auto"
            />
            <vue-avatar
              v-else
              :username="$store.state.user.user.name"
              :size="64"
              background-color="#000000"
              color="#FFFFFF"
              class="mx-auto"
            />
          </div>
          <div class="flex-grow">
            <div class="text-16">
              {{ $store.state.user.user.name }}
            </div>
            <div
              class="w-full h-6 line-champ-1"
              style="-webkit-box-orient: vertical; max-width:165px;"
            >
              {{ $store.state.user.user.email }}
            </div>
          </div>
        </div>
        <hr>
        <div>
          <nuxt-link
            class="btn border rounded-20 w-full mb-3 border-eee"
            to="/user"
            @click.native="$store.commit('site/showProfileModal', false)"
          >
            帳號設定
          </nuxt-link>
          
          <nuxt-link
            class="btn border rounded-20 w-full mb-3 border-eee"
            to="/orders"
            @click.native="$store.commit('site/showProfileModal', false)"
          >
            訂單紀錄
          </nuxt-link>

          <nuxt-link
            class="btn border rounded-20 w-full mb-8 border-eee"
            to="/user-order-subscribe/listing"
            @click.native="$store.commit('site/showProfileModal', false)"
          >
            訂閱控制台
          </nuxt-link> 
          <button
            class="btn border rounded-20 text-blue w-full border-eee"
            @click="logoutDo()"
          >
            登出
          </button>
        </div>
      </div>
    </div>

    <!--
    ..######..########....###....########...######..##.....##....##.....##..#######..########.....###....##......
    .##....##.##.........##.##...##.....##.##....##.##.....##....###...###.##.....##.##.....##...##.##...##......
    .##.......##........##...##..##.....##.##.......##.....##....####.####.##.....##.##.....##..##...##..##......
    ..######..######...##.....##.########..##.......#########....##.###.##.##.....##.##.....##.##.....##.##......
    .......##.##.......#########.##...##...##.......##.....##....##.....##.##.....##.##.....##.#########.##......
    .##....##.##.......##.....##.##....##..##....##.##.....##....##.....##.##.....##.##.....##.##.....##.##......
    ..######..########.##.....##.##.....##..######..##.....##....##.....##..#######..########..##.....##.########
    -->
    <div
      class="pin-x pin-y fixed w-screen h-screen modal-login "
      :class=" { 'active': $store.state.site.isShowSearch }"
      @click="$store.commit('site/showSearchModal', false)"
    >
      <div class="container py-20 lg:py-20 hidden lg:block">
        <div
          class="bg-white px-0 py-0 rounded-10 border-yada  bg-f9f9f9 overflow-hidden"
          style="box-shadow: 0 0 10px rgba(0, 0, 0, 0.1)"
          @click.stop.prevent
        >
          <form
            aria-label="商品"
            role="search"
            class="flex bg-white py-4"
            @submit.prevent="onSearchBarSubmit"
            @keyup.esc.prevent="clearSearchBar"
          >
            <button
              aria-label="搜尋"
              title="進行搜尋"
              type="submit"
              class="flex-none px-4"
            >
              <div class="icon-unipapa icon-search text-26" />
            </button>
            <input
              id="search2"
              ref="searchBar"
              v-model="searchValue"
              type="search"
              placeholder="輸入關鍵字搜尋 Unipapa.com"
              aria-label="搜尋商品"
              class="flex-auto appearance-none leading-none focus:outline-none px-5 rounded-none bg-none"
              required
              @focus="onSearchBarFocus"
              @blur="onSearchBarBlur"
              @keyup.enter="onSearchBarSubmit"
            >
            <button
              type="button"
              aria-label="關閉"
              title="取消搜尋"
              class="flex-none px-4"
              @click="clearSearchBar"
            >
              <div class="icon-unipapa icon-cross text-26" />
            </button>
          </form>
          <div class="py-5 px-5">
            <div class="text-12 text-999 mb-3">
              或許您會想找：
            </div>
            <div class="flex flex-wrap -mx-3">
              <nuxt-link
                class="w-1/5 px-3"
                to="/article/listing"
              >
                <div class="flex rounded-5 bg-white items-center px-3 py-3 border-yada haohao">
                  <div class="flex-auto text-center">
                    文章特集
                  </div>
                </div>
              </nuxt-link>
              <nuxt-link
                class="w-1/5 px-3"
                to="/shop/index"
              >
                <div class="flex rounded-5 bg-white items-center px-3 py-3 border-yada haohao">
                  <div class="flex-auto text-center">
                    實體販售地點
                  </div>
                </div>
              </nuxt-link>
              <nuxt-link
                class="w-1/5 px-3"
                to="/order-subscribe/index"
              >
                <div class="flex rounded-5 bg-white items-center px-3 py-3 border-yada haohao">
                  <div class="flex-auto text-center">
                    叮咚！訂閱配送
                  </div>
                </div>
              </nuxt-link>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--
    ....##......##......##......##......########.##.....##.########.##....##.########
    ..####....####....####....####......##.......##.....##.##.......###...##....##...
    ....##......##......##......##......##.......##.....##.##.......####..##....##...
    ....##......##......##......##......######...##.....##.######...##.##.##....##...
    ....##......##......##......##......##........##...##..##.......##..####....##...
    ....##......##......##......##......##.........##.##...##.......##...###....##...
    ..######..######..######..######....########....###....########.##....##....##...
    -->
    <div
      v-if="$store.state.site.isShow1111"
      class="fixed h-screen w-screen lg:hidden"
      style="background:rgba(0,0,0,0.5); left:0; top:0; z-index:100"
    />

    <div
      v-if="$store.state.site.isShow1111"
      class="fixed bg-white px-4 py-4 modal-notification-frame border-yada rounded-10 shadow"
    >
      <!-- <div class="flex items-center">
        <div class="mr-3 font-bold text-16">
          「雙俗壹柑仔店」優惠活動倒數！！
        </div>
        <div class="ml-auto">
          <button
            class="border-yada text-blue px-2 py-1 rounded-5"
            @click="isShow1111 = false"
          >
            關閉
          </button>
        </div>
      </div>
      <hr class="my-3"> -->
    
      <div class="text-blue text-16 text-center">
        <!-- 101日 23時 53分 23秒 34.60 -->
        <!-- {{ countdown.day | pad(2) }}日 {{ countdown.hour | pad(2) }}時 {{ countdown.minute | pad(2) }}分 {{ countdown.second | pad(2) }}秒 {{ countdown.milesecond | pad(3) }} -->
        <!-- {{ countdown.day | pad(2) }}日 {{ countdown.hour | pad(2) }}時 {{ countdown.minute | pad(2) }}分 {{ countdown.second | pad(2) }}秒 -->
        雙俗壹柑仔店優惠進行中！
      </div>

      <div class="text-center my-2 text-999">
        (11月11日23時59分為止)
      </div>
      <hr class="my-3">
      <!-- <a
        class="bg-blue text-white py-2 rounded-5 w-full text-16 block text-center"
        href="https://calendar.google.com/calendar/u/0/r/eventedit?trp=true&sf=true&output=xml&dates=20201105T040000Z/20201111T155900Z&details=Unipapa%E9%9B%99%E4%BF%97%E5%A3%B9%E6%9F%91%E4%BB%94%E5%BA%97%E5%85%A8%E5%B9%B4%E6%9C%80%E4%BF%97%E5%8F%AA%E5%9C%A811/5-11/11%3Cbr%3E%E9%80%B2%E5%85%A5%3Ca+href%3D%22https://www.unipapa.com%22%3EUnipapa%E5%AE%98%E7%B6%B2%3C/a%3E&location=%E9%9B%99%E4%BF%97%E5%A3%B9%E6%9F%91%E4%BB%94%E5%BA%97&text=Unipapa%E9%9B%99%E4%BF%97%E5%A3%B9%E6%9F%91%E4%BB%94%E5%BA%97%E9%96%8B%E8%B3%A3&fbclid=IwAR1SRwOiU7p7A6SwXERJvp73fWpPg3h7It_liO5ClUFBEWuhw9ptPSYYU9o"
        target="_blank"
        @click="isShow1111 = false"
      >
        Google 行事曆提醒
      </a> -->
      <nuxt-link
        class="bg-blue text-white py-2 rounded-5 w-full text-14 block text-center"
        to="/page/item/Shuangsuyiganzai-shop"
      >
        前往搶購
      </nuxt-link>
    </div>

    <!--
    .##....##..#######..########.####.########.####..######.....###....########.####..#######..##....##
    .###...##.##.....##....##.....##..##........##..##....##...##.##......##.....##..##.....##.###...##
    .####..##.##.....##....##.....##..##........##..##........##...##.....##.....##..##.....##.####..##
    .##.##.##.##.....##....##.....##..######....##..##.......##.....##....##.....##..##.....##.##.##.##
    .##..####.##.....##....##.....##..##........##..##.......#########....##.....##..##.....##.##..####
    .##...###.##.....##....##.....##..##........##..##....##.##.....##....##.....##..##.....##.##...###
    .##....##..#######.....##....####.##.......####..######..##.....##....##....####..#######..##....##
    -->
    <div
      v-if="$store.state.site.notification.length > 0"
      class="fixed modal-notification-frame "
    >
      <notification />
    </div>


    <!--
    .########.##........#######.....###....########.####.##....##..######..
    .##.......##.......##.....##...##.##......##.....##..###...##.##....##.
    .##.......##.......##.....##..##...##.....##.....##..####..##.##.......
    .######...##.......##.....##.##.....##....##.....##..##.##.##.##...####
    .##.......##.......##.....##.#########....##.....##..##..####.##....##.
    .##.......##.......##.....##.##.....##....##.....##..##...###.##....##.
    .##.......########..#######..##.....##....##....####.##....##..######..
    -->
    <!-- v-if="isShowFloating && !isOn67w" -->
    <!-- v-if="isShowFloating && !isOn20241111" -->
    <div
      v-if="isShowFloating" 
      class="fixed"
      style="top: 10%; left: 10px; z-index: 9999" 
    >
      <div class="relative">
        <!-- 67w -->
        <!--         
        <nuxt-link
          to="/products/67w-charger?utm_source=website&utm_medium=sidebar&utm_campaign=67wcharger"
          @click.native="isShowFloating=false"
        >
          <img
            src="https://d1jjjfa1mlyr2v.cloudfront.net/u/2024-10-22-06-56-05_bbc4fecdc486e42b4eeec6da77b1f818.gif"
            style="width:180px"
          >
        </nuxt-link>
        <img
          src="https://d1jjjfa1mlyr2v.cloudfront.net/u/2024-10-22-06-44-30_e87d81360c2d23e6368bf641b59ad9a0.png"
          class="w-6 pointer absolute"
          style="top: 0px; right: 0px"
          @click="isShowFloating=false"
        > -->

        <!-- 2024-1111 -->
        <!-- <nuxt-link
          to="/promotion/2024-1111"
          @click.native="isShowFloating=false"
        >
          <img
            src="https://d1jjjfa1mlyr2v.cloudfront.net/u/2024-11-07-09-08-57_e2eb1a6ba1e2cd945edc35cc636e50b3.png"
            style="width:180px"
          >
          
        </nuxt-link>

        <img
          src="https://d1jjjfa1mlyr2v.cloudfront.net/u/2024-10-22-06-44-30_e87d81360c2d23e6368bf641b59ad9a0.png"
          class="w-6 pointer absolute"
          style="top: 0px; right: 0px"
          @click="isShowFloating=false"
        > -->

        <nuxt-link
          to="/campaign/item/charge-spring2025"
          @click.native="isShowFloating=false"
        >
          <img
            src="https://d1jjjfa1mlyr2v.cloudfront.net/u/b757b1c94576b3a0d061eb3210dfdde1.png"
            style="width: 160px"
          >
        </nuxt-link>

        <img
          src="https://d1jjjfa1mlyr2v.cloudfront.net/u/eb825ea882b355b91e5cd44dafef676b.png"
          class="w-6 pointer absolute"
          style="top: 0px; right: 0px"
          @click="isShowFloating=false"
        >
      </div>
    </div>
  </div>
</template>

<script>
import Cookies from 'js-cookie';
import TheHeaderMd from '../components/TheHeaderMd.vue';
import TheHeader from '../components/TheHeader.vue';
import notification from '../components/notification.vue';

export default {
  components: {
    TheHeaderMd,
    TheHeader,
    notification,
  },

  data() {
    return {
      isReady: true,
      searchValue: '',
      isSearchBarOpen: false,
      countdown: {
        day: 3,
        hour: 13,
        minute: 23,
        second: 59,
        milesecond: '00.0',
      },
      isCountdownReady: false,
      countdownTime: 0,
      // isShowFloating: false,
      isShowFloating: true,
      isOn67w: false,
      isOn20241111: false,
    };
  },

  computed: {
    headerPosition() {
      return (this.$route.meta.isHeaderFixed === undefined
      ? true
      : this.$route.meta.isHeaderFixed)
        ? 'fixed'
        : 'absolute';
    },
  },

  watch: {
    $route() {
      // 67w
      // if (to.path == '/products/67w-charger') {
      //   this.isShowFloating = false;
      //   this.isOn67w = true;
      // }
      // 2024-1111
      /*
      if (to.path == '/promotion/2024-1111') {
        this.isShowFloating = false;
        this.isOn20241111 = true;
      }
        */
    },
  },

  created() {
    this.initCart();
  },

  async mounted() {
    const self = this;

    if (this.$auth.getToken() && !window.isCheckedLoginStatus) {
      try {
        const result = await this.post(`auth/token`, {}).catch(() => {
          // remove cookie token
          self.$auth.logout();
        });
        window.isCheckedLoginStatus = true;

        const data = result.data;

        this.$store.commit('user/setUser', {
          user: data.user,
          coupons: data.coupons,
          isNewUser: data.isNewUser,
        });
      } catch (e) {}
    }

    // this.checkFloating();

    this.initDatalayer();

    this.countdownTime = new Date('2020-11-05T12:00:00+08:00').valueOf();

    if (this.isDev) {
      // this.isShow1111 = false;
    } else {
      if (
        new Date('2020-11-05T12:00:00+08:00').valueOf() < new Date().valueOf()
      ) {
        // this.isShow1111 = false;
      }
    }

    // check is invalid, remove cookie token
    if (this.$store.state.site.isInvalidToken) {
      try {
        Cookies.remove('token');
        this.$store.commit('site/setInvalid', false);
      } catch (e) {}
    }

    // check has utm url

    if (
      document.referrer &&
      document.referrer !== window.location.href // Ensure referrer is not the current URL
    ) {
      try {
        // get tracingID if available
        let tracingID = localStorage.getItem('tracingID');

        const postData = {
          _model: 'tracing',
          url: window.location.href, // Save current URL
          referralUrl: document.referrer || null, // Save referral URL
        };

        if (this.$route.query.utm_source) {
          postData.utmSource = this.$route.query.utm_source;
        }

        if (this.$route.query.utm_medium) {
          postData.utmMedium = this.$route.query.utm_medium;
        }

        if (this.$route.query.utm_campaign) {
          postData.utmCampaign = this.$route.query.utm_campaign;
        }

        if (tracingID) {
          tracingID = parseInt(tracingID);
          postData.parentID = tracingID;
        }

        // get tracingID
        const result = await this.post(`update/update`, postData);
        localStorage.setItem('tracingID', result.data.data.item.id);
      } catch (e) {}
    }
  },

  methods: {
    checkFloating() {
      let lastShowFloating = localStorage.getItem('lastShowFloating');

      // if (document.location.pathname == '/products/67w-charger') {
      //   this.isOn67w = true;
      // }

      try {
        if (!lastShowFloating) {
          lastShowFloating = new Date().valueOf();
          localStorage.setItem('lastShowFloating', lastShowFloating);
          this.isShowFloating = true;
        } else {
          lastShowFloating = parseInt(lastShowFloating);

          const yy = new Date('2025-01-17 05:00:00+08:00').valueOf();
          if (yy > lastShowFloating) {
            lastShowFloating = 0;
          }
          let now = new Date().valueOf();
          if (now - lastShowFloating > 1000 * 60 * 60 * 24 * 1) {
            this.isShowFloating = true;
            localStorage.setItem('lastShowFloating', now);
          }
        }

        if (document.location.pathname == '/') {
          this.isShowFloating = true;
        }
      } catch (e) {}
    },

    // ....##......##......##......##......########.##.....##.########.##....##.########
    // ..####....####....####....####......##.......##.....##.##.......###...##....##...
    // ....##......##......##......##......##.......##.....##.##.......####..##....##...
    // ....##......##......##......##......######...##.....##.######...##.##.##....##...
    // ....##......##......##......##......##........##...##..##.......##..####....##...
    // ....##......##......##......##......##.........##.##...##.......##...###....##...
    // ..######..######..######..######....########....###....########.##....##....##...
    startTimerMilesecond() {
      let milesecond = 1000 - new Date().getMilliseconds();
      this.countdown.milesecond = milesecond;
      setTimeout(this.startTimerMilesecond, 20);
    },

    startTimer() {
      const now = new Date().valueOf();

      if (this.countdown.second <= 0 || this.isCountdownReady == false) {
        let differ = this.countdownTime - now;

        const day = Number.parseInt(differ / (60 * 60 * 24 * 1000));
        differ -= day * 60 * 60 * 24 * 1000;

        let hour = Number.parseInt(differ / (60 * 60 * 1000));
        differ -= hour * 60 * 60 * 1000;

        let minute = Number.parseInt(differ / (60 * 1000));
        differ -= minute * 60 * 1000;

        let second = Number.parseInt(differ / 1000);

        this.countdown.day = day;
        this.countdown.hour = hour;
        this.countdown.minute = minute;
        this.countdown.second = second;
      } else {
        this.countdown.second -= 1;
      }

      this.isCountdownReady = true;

      setTimeout(this.startTimer, 1000);
    },

    logoutDo() {
      this.$store.commit('site/showProfileModal', false);
      this.$auth.logout();
      this.$router.push('/');
      // location.reload();
    },

    // search
    clearSearchBar() {
      this.searchValue = '';
      this.isSearchBarOpen = false;
      this.$refs.searchBar.blur();
      this.$store.commit('site/showSearchModal', false);
    },

    onSearchBarFocus() {
      this.isSearchBarOpen = true;
    },

    onSearchBarBlur() {
      if (!this.searchValue) {
        this.isSearchBarOpen = false;
      }
    },

    onSearchBarSubmit() {
      // this.$router.push(`/search/${this.searchValue}`);
      this.$router.push(`/search?query=${this.searchValue}`);
      this.clearSearchBar();
      this.$store.commit('site/showSearchModal', false);
    },
  },
};
</script>

<style module>
.is-push-down {
  padding-top: var(--header-height);
}
</style>
