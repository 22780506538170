import area from './area';
import city from './city';
import receiptType from './receiptType';
import receiptCarrierType from './receiptCarrierType';
import shipmentCompany from './shipmentCompany';
import processStatus from './processStatus';
import gender from './gender';
import subscribe from './subscribe';
import tappayCardType from './tappayCardType';
import productType from './productType';
import productType2 from './productType2';
import productType3 from './productType3';
import articleType from './articleType';
import productBrandType from './productBrandType';
import shopArea from './shopArea';
import shopStore from './shopStore';
import paymentType from './paymentType';
import purchasePurpose from './purchasePurpose';
import refundReason from './refundReason';
import warrantyType from './warrantyType';

import { TAPPAY_TYPE, TAPPAY_FIELDS_STATUS_CODE } from './TAPPAY';
import { findBy } from '../helper';

const ASSETS_BASE_URL = process.env.ASSETS_BASE_URL;
const API_ORIGIN = process.env.API_ORIGIN;
const GRAPHQL_ENDPOINT = process.env.GRAPHQL_ENDPOINT;
const CANONICAL_ORIGIN = process.env.CANONICAL_ORIGIN;

/** 預設的發票類型 */
const DEFAULT_RECEIPT_TYPE = findBy(receiptType, 'identifier', 'EINVOICE');

/** 發票類型 - 歸戶至發票載具 */
const RECEIPT_TYPE_CARRIER = findBy(receiptType, 'identifier', 'CARRIER');

/** 發票類型 - 三聯式發票 */
const RECEIPT_TYPE_TRIPLICATE = findBy(receiptType, 'identifier', 'TRIPLICATE');

/** 預設的發票載具類型 */
const DEFAULT_RECEIPT_CARRIER_TYPE = findBy(
  receiptCarrierType,
  'identifier',
  'MOBILE'
);

/** 免運費門檻 */
const FREE_SHIPPING_AMOUNT = 1500;
const SHIPPING_FEE = 100;

/** 備註文字跟備註選項的分隔符號 */
// const MEMO_SEPARATOR = '|||';

const EMPTY_PHOTO =
  'https://d1jjjfa1mlyr2v.cloudfront.net/u/a71827fb4e1c85cee5cd2d4a41d77db9.jpg';

// 2020 1111
// const now = new Date().getTime();
// const periodFrom1111 = new Date('2020-11-05T11:30:00+08:00');
// const periodTo1111 = new Date('2020-11-12T02:00:00+08:00');
// const isPeriod1111 = periodFrom1111 <= now && periodTo1111 >= now;
// const isPeriod1111 = false;

const item = {
  EMPTY_PHOTO,
  ASSETS_BASE_URL,
  API_ORIGIN,
  GRAPHQL_ENDPOINT,
  CANONICAL_ORIGIN,
  DEFAULT_RECEIPT_TYPE,
  RECEIPT_TYPE_TRIPLICATE,
  DEFAULT_RECEIPT_CARRIER_TYPE,
  FREE_SHIPPING_AMOUNT,
  // MEMO_SEPARATOR,
  TAPPAY_TYPE,
  TAPPAY_FIELDS_STATUS_CODE,
  SHIPPING_FEE,
  RECEIPT_TYPE_CARRIER,

  receiptCarrierType,
  // productRedirectMap,
  // collection,
  area,
  city,
  receiptType,
  shipmentCompany,
  processStatus,
  gender,
  subscribe,
  tappayCardType,
  productType,
  productType2,
  productType3,
  articleType,
  productBrandType,
  shopArea,
  shopStore,
  // isPeriod1111,
  paymentType,
  purchasePurpose,
  refundReason,
  warrantyType,
};

export default item;

export {
  EMPTY_PHOTO,
  ASSETS_BASE_URL,
  API_ORIGIN,
  GRAPHQL_ENDPOINT,
  CANONICAL_ORIGIN,
  DEFAULT_RECEIPT_TYPE,
  RECEIPT_TYPE_TRIPLICATE,
  DEFAULT_RECEIPT_CARRIER_TYPE,
  FREE_SHIPPING_AMOUNT,
  // MEMO_SEPARATOR,
  TAPPAY_TYPE,
  TAPPAY_FIELDS_STATUS_CODE,
  SHIPPING_FEE,
  RECEIPT_TYPE_CARRIER,
  receiptCarrierType,
  // productRedirectMap,
  // collection,
  area,
  city,
  receiptType,
  shipmentCompany,
  processStatus,
  gender,
  subscribe,
  tappayCardType,
  productType,
  productType2,
  productType3,
  articleType,
  productBrandType,
  shopArea,
  shopStore,
  // isPeriod1111,
  paymentType,
  purchasePurpose,
  refundReason,
  warrantyType,
};
