export function state() {
  return {
    isShowLoginModal: false,
    isShowProfileModal: false,
    isShowSearch: false,
    notification: [],
    isShow1111: false,
    isInvalidToken: false,
  };
}

export const mutations = {
  changeNotification(state, index) {
    state.notification[index].isShowConfirm = true;
  },

  addNotification(state, q) {
    if (q.typeID == 2) {
      q.isShowConfirm = false;
    }
    state.notification.unshift(q);
  },

  showLoginModal(state, is) {
    state.isShowLoginModal = is;
  },

  showProfileModal(state, is) {
    state.isShowProfileModal = is;
  },

  showSearchModal(state, is) {
    state.isShowSearch = is;
  },

  clearNotifications(state) {
    state.notification = [];
  },

  clearNotification(state, index) {
    state.notification.splice(index, 1);
  },

  setInvalid(state, is) {
    state.isInvalidToken = is;
  },
};
